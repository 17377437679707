/* eslint-disable @typescript-eslint/no-explicit-any */
import { Record } from 'immutable';

import { makeRequest } from '@/utils';

type Props = {
  params?: any;
  id?: number;
  errors: any;
};

type setContactProps = {
  name: string;
  email: string;
  birthYear: number | string;
  birthMonth: number | string;
  birthDay: number | string;
  gender: string;
  message: string;
};

const WordpressRecord = Record<Props>({
  errors: {},
});

const base = process.env.WORDPRESS_URL;

export default class Wordpress extends WordpressRecord {
  setContact({
    name,
    email,
    birthYear,
    birthMonth,
    birthDay,
    gender,
    message,
  }: setContactProps): Promise<any> {
    return new Promise((resolve, reject) => {
      const sendRequest = async () => {
        try {
          const params = {
            name,
            email,
            birthYear,
            birthMonth,
            birthDay,
            gender,
            message,
          };

          const response = await makeRequest({
            url: base + '/api/app/v1/contacts',
            method: 'POST',
            data: params,
          });
          resolve(response);
        } catch (error) {
          reject(error);
        }
      };
      return sendRequest();
    });
  }
}
