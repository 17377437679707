import Master from '@/models/Master';
import { Master as MasterType } from '@/types';

export default function master(
  state = new Master(),
  action: MasterType
): Master {
  switch (action.type) {
    case 'REQUEST_PROCESS':
      return state.set('isProcessing', true);
    case 'COMPLETE_PROCESS':
      return state.set('isProcessing', false);
    default:
      return state;
  }
}
