import React, { Component, JSX } from 'react';
import { Provider } from 'react-redux';

import store from './src/store';
import * as muiTheme from './src/muiTheme';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';

type Props = {
  children: React.ReactNode;
};
class Main extends Component<Props> {
  render() {
    return <ThemeProvider theme={theme}>{this.props.children}</ThemeProvider>;
  }
}

// Create a theme instance.
const theme = createTheme(muiTheme.theme);

export default ({ element }: { element: JSX.Element }): JSX.Element => {
  return (
    <Provider store={store}>
      <Main>{element}</Main>
    </Provider>
  );
};
