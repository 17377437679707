/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'axios';
import { v4 as uuid } from 'uuid';

type ArgsType = {
  url?: string;
  method?: string;
  data?: any | null;
  headers?: any;
};

export default function makeRequest({
  url,
  method,
  data,
  headers,
}: ArgsType = {}): Promise<any> {
  return new Promise((resolve, reject) => {
    const requestId = uuid();
    let response: { status: number; data: any };
    let status = 500;

    const defaultHeaders: Record<string, string> = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-Request-Id': requestId,
      'X-CSRF-TOKEN': (window as any)['csrf-token'].content,
    };

    const makeRequestAsync = async () => {
      try {
        response = await request({
          url,
          method,
          data,
          headers: Object.assign(defaultHeaders, headers),
        });
        if (response) status = response.status;

        if (status >= 200 && status < 300) {
          return resolve(response);
        } else {
          return reject(response); // 必要？
        }
      } catch (error) {
        response = error.response;

        if (response) {
          status = response.status;

          if (response.data.errorMsg && response.data.requestId) {
            response.data.errorMsg =
              response.data.errorMsg +
              '\n\nリクエストID: ' +
              response.data.requestId;
          }
        }

        return reject(response);
      }
    };

    return makeRequestAsync();
  });
}
