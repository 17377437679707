import { combineReducers } from 'redux';

import master from './master';
import wordpress from './wordpress';

const rootReducer = combineReducers({
  master,
  wordpress,
});

export default rootReducer;
