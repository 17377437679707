/* eslint-disable @typescript-eslint/no-explicit-any */
import { Record } from 'immutable';

type Props = {
  isProcessing: boolean;
  errors: any;
};

const MasterRecord = Record<Props>({
  isProcessing: false,
  errors: {},
});

export default class Master extends MasterRecord {}
