import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';

import rootReducer from '@/reducers';

//export const validateMiddleware = ({ dispatch }) => next => action => {
//  if (Example.isValid(dispatch, action)) return;
//  return next(action);
//};

const composeEnhancers = compose;
const middlewares = [thunkMiddleware];

const enhancer = composeEnhancers(applyMiddleware(...middlewares));

const store = createStore(combineReducers({ rootReducer }), enhancer);

export default store;
