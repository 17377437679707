import Wordpress from '@/models/Wordpress';
import { Master as MasterType } from '@/types';

export default function wordpress(
  state = new Wordpress(),
  action: MasterType
): Wordpress {
  switch (action.type) {
    default:
      return state;
  }
}
